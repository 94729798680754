<template>
  <vue-final-modal v-model="show" @click-outside="hide">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button @click="$emit('close')" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
          <p v-html="content"></p>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'

export default {
  name: 'InfoModal',
  components: {
    VueFinalModal,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      required: true,
    },
    hide: {
      type: Function,
      required: false,
    },
  },
  data: () => ({
    show: false,
  }),
}
</script>
